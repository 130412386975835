exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-best-ai-crypto-trading-bots-js": () => import("./../../../src/pages/blog/best-ai-crypto-trading-bots.js" /* webpackChunkName: "component---src-pages-blog-best-ai-crypto-trading-bots-js" */),
  "component---src-pages-blog-blockchain-in-pharma-industry-js": () => import("./../../../src/pages/blog/blockchain-in-pharma-industry.js" /* webpackChunkName: "component---src-pages-blog-blockchain-in-pharma-industry-js" */),
  "component---src-pages-blog-blockchain-supply-chain-js": () => import("./../../../src/pages/blog/blockchain-supply-chain.js" /* webpackChunkName: "component---src-pages-blog-blockchain-supply-chain-js" */),
  "component---src-pages-blog-consensus-mechanisms-js": () => import("./../../../src/pages/blog/consensus-mechanisms.js" /* webpackChunkName: "component---src-pages-blog-consensus-mechanisms-js" */),
  "component---src-pages-blog-cryptocurrency-exchange-development-tools-and-frameworks-js": () => import("./../../../src/pages/blog/cryptocurrency-exchange-development-tools-and-frameworks.js" /* webpackChunkName: "component---src-pages-blog-cryptocurrency-exchange-development-tools-and-frameworks-js" */),
  "component---src-pages-blog-cryptocurrency-laws-and-regulations-2024-js": () => import("./../../../src/pages/blog/cryptocurrency-laws-and-regulations-2024.js" /* webpackChunkName: "component---src-pages-blog-cryptocurrency-laws-and-regulations-2024-js" */),
  "component---src-pages-blog-ecommerce-automotive-industry-js": () => import("./../../../src/pages/blog/ecommerce-automotive-industry.js" /* webpackChunkName: "component---src-pages-blog-ecommerce-automotive-industry-js" */),
  "component---src-pages-blog-elevate-video-conference-experience-with-bigbluebutton-webrtc-js": () => import("./../../../src/pages/blog/elevate-video-conference-experience-with-bigbluebutton-webrtc.js" /* webpackChunkName: "component---src-pages-blog-elevate-video-conference-experience-with-bigbluebutton-webrtc-js" */),
  "component---src-pages-blog-exclusive-complete-guide-decentralized-finance-js": () => import("./../../../src/pages/blog/exclusive-complete-guide-decentralized-finance.js" /* webpackChunkName: "component---src-pages-blog-exclusive-complete-guide-decentralized-finance-js" */),
  "component---src-pages-blog-explore-the-features-of-wazirx-clone-script-js": () => import("./../../../src/pages/blog/explore-the-features-of-wazirx-clone-script.js" /* webpackChunkName: "component---src-pages-blog-explore-the-features-of-wazirx-clone-script-js" */),
  "component---src-pages-blog-free-crypto-trading-bots-2024-js": () => import("./../../../src/pages/blog/free-crypto-trading-bots-2024.js" /* webpackChunkName: "component---src-pages-blog-free-crypto-trading-bots-2024-js" */),
  "component---src-pages-blog-how-to-build-a-crypto-exchange-like-binance-js": () => import("./../../../src/pages/blog/how-to-build-a-crypto-exchange-like-binance.js" /* webpackChunkName: "component---src-pages-blog-how-to-build-a-crypto-exchange-like-binance-js" */),
  "component---src-pages-blog-how-to-build-software-product-guide-for-beginners-js": () => import("./../../../src/pages/blog/how-to-build-software-product-guide-for-beginners.js" /* webpackChunkName: "component---src-pages-blog-how-to-build-software-product-guide-for-beginners-js" */),
  "component---src-pages-blog-how-to-launch-a-crypto-exchange-business-similar-to-wazirx-js": () => import("./../../../src/pages/blog/how-to-launch-a-crypto-exchange-business-similar-to-wazirx.js" /* webpackChunkName: "component---src-pages-blog-how-to-launch-a-crypto-exchange-business-similar-to-wazirx-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-list-blog-details-index-js": () => import("./../../../src/pages/blog-list/[blogDetails]/index.js" /* webpackChunkName: "component---src-pages-blog-list-blog-details-index-js" */),
  "component---src-pages-blog-list-index-js": () => import("./../../../src/pages/blog-list/index.js" /* webpackChunkName: "component---src-pages-blog-list-index-js" */),
  "component---src-pages-blog-revolutionary-strategies-earning-passive-income-crypto-space-js": () => import("./../../../src/pages/blog/revolutionary-strategies-earning-passive-income-crypto-space.js" /* webpackChunkName: "component---src-pages-blog-revolutionary-strategies-earning-passive-income-crypto-space-js" */),
  "component---src-pages-blog-tokenization-in-revolutionizing-blockchain-js": () => import("./../../../src/pages/blog/tokenization-in-revolutionizing-blockchain.js" /* webpackChunkName: "component---src-pages-blog-tokenization-in-revolutionizing-blockchain-js" */),
  "component---src-pages-blog-web-3-arena-graph-revolution-js": () => import("./../../../src/pages/blog/web3-arena-graph-revolution.js" /* webpackChunkName: "component---src-pages-blog-web-3-arena-graph-revolution-js" */),
  "component---src-pages-blog-web-3-in-ecommerce-js": () => import("./../../../src/pages/blog/web3-in-ecommerce.js" /* webpackChunkName: "component---src-pages-blog-web-3-in-ecommerce-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-careers-list-index-js": () => import("./../../../src/pages/careers-list/index.js" /* webpackChunkName: "component---src-pages-careers-list-index-js" */),
  "component---src-pages-centralized-crypto-exchange-development-js": () => import("./../../../src/pages/centralized-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-centralized-crypto-exchange-development-js" */),
  "component---src-pages-clone-script-js": () => import("./../../../src/pages/clone-script.js" /* webpackChunkName: "component---src-pages-clone-script-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cryptocurrency-exchange-development-company-js": () => import("./../../../src/pages/cryptocurrency-exchange-development-company.js" /* webpackChunkName: "component---src-pages-cryptocurrency-exchange-development-company-js" */),
  "component---src-pages-cryptocurrency-mlm-software-development-js": () => import("./../../../src/pages/cryptocurrency-mlm-software-development.js" /* webpackChunkName: "component---src-pages-cryptocurrency-mlm-software-development-js" */),
  "component---src-pages-custom-web-development-company-js": () => import("./../../../src/pages/custom-web-development-company.js" /* webpackChunkName: "component---src-pages-custom-web-development-company-js" */),
  "component---src-pages-dao-development-company-js": () => import("./../../../src/pages/dao-development-company.js" /* webpackChunkName: "component---src-pages-dao-development-company-js" */),
  "component---src-pages-decentralized-exchange-development-js": () => import("./../../../src/pages/decentralized-exchange-development.js" /* webpackChunkName: "component---src-pages-decentralized-exchange-development-js" */),
  "component---src-pages-defi-development-company-js": () => import("./../../../src/pages/defi-development-company.js" /* webpackChunkName: "component---src-pages-defi-development-company-js" */),
  "component---src-pages-defi-dex-aggregator-platform-development-js": () => import("./../../../src/pages/defi-dex-aggregator-platform-development.js" /* webpackChunkName: "component---src-pages-defi-dex-aggregator-platform-development-js" */),
  "component---src-pages-enterprise-blockchain-development-js": () => import("./../../../src/pages/enterprise-blockchain-development.js" /* webpackChunkName: "component---src-pages-enterprise-blockchain-development-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matrimony-clone-script-js": () => import("./../../../src/pages/matrimony-clone-script.js" /* webpackChunkName: "component---src-pages-matrimony-clone-script-js" */),
  "component---src-pages-mean-and-mern-full-stack-development-js": () => import("./../../../src/pages/mean-and-mern-full-stack-development.js" /* webpackChunkName: "component---src-pages-mean-and-mern-full-stack-development-js" */),
  "component---src-pages-nft-development-company-js": () => import("./../../../src/pages/nft-development-company.js" /* webpackChunkName: "component---src-pages-nft-development-company-js" */),
  "component---src-pages-nft-marketplace-development-company-js": () => import("./../../../src/pages/nft-marketplace-development-company.js" /* webpackChunkName: "component---src-pages-nft-marketplace-development-company-js" */),
  "component---src-pages-p-2-p-crypto-exchange-development-js": () => import("./../../../src/pages/p2p-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-p-2-p-crypto-exchange-development-js" */),
  "component---src-pages-poc-development-company-js": () => import("./../../../src/pages/poc-development-company.js" /* webpackChunkName: "component---src-pages-poc-development-company-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-smart-contract-development-company-js": () => import("./../../../src/pages/smart-contract-development-company.js" /* webpackChunkName: "component---src-pages-smart-contract-development-company-js" */),
  "component---src-pages-stock-trading-app-development-company-js": () => import("./../../../src/pages/stock-trading-app-development-company.js" /* webpackChunkName: "component---src-pages-stock-trading-app-development-company-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms-condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-pages-ton-wallet-development-company-js": () => import("./../../../src/pages/ton-wallet-development-company.js" /* webpackChunkName: "component---src-pages-ton-wallet-development-company-js" */),
  "component---src-pages-wazirx-clone-script-js": () => import("./../../../src/pages/wazirx-clone-script.js" /* webpackChunkName: "component---src-pages-wazirx-clone-script-js" */),
  "component---src-pages-web-3-development-company-js": () => import("./../../../src/pages/web3-development-company.js" /* webpackChunkName: "component---src-pages-web-3-development-company-js" */),
  "component---src-pages-web-3-smart-contract-development-company-js": () => import("./../../../src/pages/web3-smart-contract-development-company.js" /* webpackChunkName: "component---src-pages-web-3-smart-contract-development-company-js" */),
  "component---src-pages-white-label-crypto-exchange-development-js": () => import("./../../../src/pages/white-label-crypto-exchange-development.js" /* webpackChunkName: "component---src-pages-white-label-crypto-exchange-development-js" */)
}

